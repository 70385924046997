.pourquoi-nous-section {
    background: linear-gradient(135deg, #f9f9f9, #e0e7ff);
    padding: 80px 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.section-title {
    font-size: 42px;
    margin-bottom: 60px;
    font-weight: 800;
    text-transform: uppercase;
    color: #333;
    position: relative;
    z-index: 1;
    opacity: 0;
    transform: translateY(-50px);
    animation: titleSlideIn 1s ease forwards;
    background: linear-gradient(90deg, #ff6e7f, #bfe9ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.points-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 40px;
    justify-items: center;
    z-index: 2;
    position: relative;
}

.point-card {
    background: white;
    border-radius: 20px;
    padding: 40px 30px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
    transition: transform 0.5s ease, box-shadow 0.5s ease, background-color 0.5s ease;
    text-align: center;
    position: relative;
    overflow: hidden;
    opacity: 0;
    transform: translateY(50px);
    animation: cardEntrance 1s ease forwards;
}

    .point-card:hover {
        transform: scale(1.05);
        box-shadow: 0 25px 50px rgba(0, 0, 0, 0.3);
        background-color: #ff6e7f10;
    }

.icon {
    font-size: 50px;
    color: #ff6e7f;
    margin-bottom: 20px;
    transition: transform 0.5s ease, color 0.5s ease;
}

.point-card:hover .icon {
    transform: rotate(360deg);
    color: #bfe9ff;
}

.point-title {
    font-size: 28px;
    color: #333;
    margin-bottom: 15px;
    transition: color 0.5s ease;
}

.point-card:hover .point-title {
    color: #ff6e7f;
}

.point-description {
    font-size: 20px;
    color: #666;
    transition: color 0.5s ease;
}

.point-card:hover .point-description {
    color: #444;
}

.custom-phrase-container {
    margin-top: 60px;
    padding: 30px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.custom-phrase {
    font-size: 28px;
    font-weight: 400;
    color: #333;
    margin: 0;
    background: linear-gradient(90deg, #ff6e7f, #bfe9ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: textGradient 3s ease infinite;
}

.highlight {
    font-weight: 700;
    color: #ff6e7f;
    text-transform: uppercase;
    transition: color 0.5s ease;
}

.custom-phrase .highlight:hover {
    color: #bfe9ff;
}

/* Animations */
@keyframes titleSlideIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes cardEntrance {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes textGradient {
    0%, 100% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }
}

.point-card:nth-child(1) {
    animation-delay: 0.5s;
}

.point-card:nth-child(2) {
    animation-delay: 1s;
}

.point-card:nth-child(3) {
    animation-delay: 1.5s;
}

.point-card:nth-child(4) {
    animation-delay: 2s;
}
