.header {
    width: 100%;
    height: 05em;
    position: fixed; 
    top: 0; 
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #282c34;
    color: white;
    text-align: center;
    overflow: hidden;
    z-index: 1000; 
}


.code-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
}

.code-line {
    font-family: 'Courier New', Courier, monospace;
    font-size: 18px;
    color: #00ff00;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, 0.75);
    margin: 0;
    padding: 0;
    opacity: 0;
    width: 0;
    animation: typing 0.5s steps(40, end) forwards, blink-caret 0.75s step-end infinite;
}

@keyframes typing {
    from {
        width: 0;
        opacity: 1;
    }

    to {
        width: 100%;
        opacity: 1;
    }
}

@keyframes blink-caret {
    from, to {
        border-color: transparent;
    }

    50% {
        border-color: rgba(255, 255, 255, 0.75);
    }
}

@keyframes fadeOutAndCollapse {
    0% {
        opacity: 1;
        height: 5em;
    }

    100% {
        opacity: 0;
        height: 0;
        overflow: hidden;
    }
}

.header.hidden {
    animation: fadeOutAndCollapse 1s forwards;
}



/* Ajout des délais pour chaque ligne */
.line-0 {
    animation-duration: 3.5s;
    animation-delay: 0s;
}

.line-1 {
    animation-duration: 3.5s;
    animation-delay: 3.5s;
}

.line-2 {
    animation-duration: 3.5s;
    animation-delay: 7s;
}

.line-3 {
    animation-duration: 3.5s;
    animation-delay: 10.5s;
}

.line-4 {
    animation-duration: 3.5s;
    animation-delay: 14s;
}

.line-5 {
    animation-duration: 3.5s;
    animation-delay: 17.5s;
}

.line-6 {
    animation-duration: 3.5s;
    animation-delay: 21s;
}

.line-7 {
    animation-duration: 3.5s;
    animation-delay: 24.5s;
}

.line-8 {
    animation-duration: 3.5s;
    animation-delay: 28s;
}
